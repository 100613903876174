<template>
  <div>
    <b-overlay :show="state.loading">
      <b-card no-body class="p-1">
        <b-form class="d-flex flex-column" @submit.prevent="submit">
          <b-form-group label="محتوی صفحه">
            <froala v-model="formData.content" :config="config"/>
          </b-form-group>
          <b-button variant="primary" type="submit">ذخیره</b-button>
        </b-form>
        <b-form-group class="p-1 m-1 border" label="فایل راهنما">
          <froalaView v-model="formData.content" :config="config"/>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'

export default {
  name: "Mission",
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
  },
  data: () => ({
    isFile: 'editor',
    formData: {
      id: '',
      title: '',
      content: '',
      name: 'MISSION'
    },
    uploadData: {
      name: "",
      file: null,
      role: ""
    },
    config: {
      events: {
        'initialized': function () {
          console.log('floara inited')
        },
        'image.beforeUpload': function (files) {
          let editor = this;
          if (files.length) {
            // Create a File Reader.
            let reader = new FileReader();
            // Set the reader to insert images when they are loaded.
            reader.onload = function (e) {
              let result = e.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };
            // Read image as base64.
            reader.readAsDataURL(files[0]);
          }
          editor.popups.hideAll();
          // Stop default upload chain.
          return false;
        }
      },
      language: 'fa',
      direction: 'rtl',
      tooltips: false,
      toolbarButtons: {
        'moreText': {
          'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        },
        'moreParagraph': {
          'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        'moreRich': {
          'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
        },
        'moreMisc': {
          'buttons': ['undo', 'redo', 'fullscreen', 'pint', 'selectAll', 'html', 'help'],
          'align': 'right',
          'buttonsVisible': 2
        }
      },
      fontFamily: {
        'Yekan': 'یکان',
      },
      fontFamilyDefaultSelection: 'Yekan',
      fontFamilySelection: false,
      key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
      attribution: false // to hide "Powered by Froala"
    },
    text: '',
    output: '',
    coWorkers: []
  }),
  methods: {
    async submit() {
      this.state.loading = true
      if (!this.formData.id) {
        const res = await this.$axios.post('/pages', this.formData)
        this.formData = res.data.data
        this.$error('درخواست با موفقیت انجام شد.', '', 'success')
      } else {
        const res = await this.$axios.patch('/pages/' + this.formData.id, this.formData)
        this.formData = res.data.data
        this.$error('درخواست با موفقیت انجام شد.', '', 'success')
      }

    },
    async getData() {
      this.state.loading = true
      const res = await this.$axios.get('/pages?name=MISSION')
      if (res.data.data && res.data.data.length > 0) {
        this.formData = res.data.data.pop()
      }
    },
  },
  mounted() {
    this.getData()
  }

}
</script>
<style lang="scss">

.custom-file-label::after {
  content: 'انتخاب فایل' !important;
}

.fr-box {
  /*text-align: left;*/
  p, ul, li, ol {
    text-align: left;
    font-family: 'Yekan', 'IRANSans', sans-serif !important;
  }

}

</style>
